<div [ngClass]="state=='open' ? 'main_content_open' : 'main_content'">
    <p-scrollPanel [style]="{width: '100%', height: '95vh','margin-top':'7px','padding-bottom':'70px'}" #sc>

        <div class="report-container">
            <h2 class="report-title">Service Utilization Report</h2>

            <div class="filter-section">
                <div>
                    <label for="clientSegmentService">Client Segment:</label>
                     <select id="clientSegmentService" [(ngModel)]="selectedClientSegmentService" (change)="onClientSegmentServiceChange()">
                        <option value="All">All</option>
                        <option *ngFor="let segment of clientSegments" [value]="segment.id">{{ segment.name }}</option>
                    </select>
                </div>

                <div class="date-range-section" style="position: relative; display: flex; align-items: center;">
                    <label for="reportDurationService">Report Duration:</label>
                    <select id="reportDurationService" [(ngModel)]="selectedDurationServiceUtilization" (change)="onReportDurationChange($event)">
                      <option value="YTD" >Year to Date (YTD)</option>
                      <option value="custom">Date Range</option>
                    </select>
                  
                    <div class="date-range" [ngClass]="selectedDurationServiceUtilization !== 'custom' ?'disabledbutton':'' ">
                      <label for="startDateService">Start Date:</label>
                      <p-calendar 
                        class="service-category"
                        id="startDateService" 
                        [(ngModel)]="startDateServiceUtilization" 
                        dateFormat="mm/dd/yy"
                        inputId="startDateService"
                        [showIcon]="true"
                        placeholder="Enter Start Date" 
                        >
                      </p-calendar>
                  
                      <label for="endDateService">End Date:</label>
                      <p-calendar 
                        class="service-category"
                        id="endDateService" 
                        [(ngModel)]="endDateServiceUtilization" 
                        dateFormat="mm/dd/yy"
                        inputId="endDateService"
                        [showIcon]="true"
                        placeholder="Enter End Date"
                        >
                      </p-calendar>
                  
                      <button 
                        (click)="fetchServiceUtilizationData()" 
                        style="margin-left: 20px; cursor: pointer; padding: 5px 10px; background-color: #2196F3;  color: #fff; border: none;">
                        Apply
                      </button>
                    </div>
                  </div>
                  
                  
              </div>

            <div class="table-scroll-container">
            <table class="report-table">
            <thead>
                <tr>
                    <th (click)="sortServiceTable('serviceName')">Service Name
                        <span>
                          <i *ngIf="sortDirectionService['serviceName'] === 'asc'" class="sort-icon">&#9650;</i>
                          <i *ngIf="sortDirectionService['serviceName'] === 'desc'" class="sort-icon">&#9660;</i>
                          <i *ngIf="sortDirectionService['serviceName'] === ''" class="sort-icon">&#9650;</i>
                        </span>
                      </th>
                      <th (click)="sortServiceTable('serviceStatus')">Service Status
                        <span>
                          <i *ngIf="sortDirectionService['serviceStatus'] === 'asc'" class="sort-icon">&#9650;</i>
                          <i *ngIf="sortDirectionService['serviceStatus'] === 'desc'" class="sort-icon">&#9660;</i>
                          <i *ngIf="sortDirectionService['serviceStatus'] === ''" class="sort-icon">&#9650;</i>
                        </span>
                      </th>
                      <th (click)="sortServiceTable('numberOfBookings')">Number of Bookings
                        <span>
                          <i *ngIf="sortDirectionService['numberOfBookings'] === 'asc'" class="sort-icon">&#9650;</i>
                          <i *ngIf="sortDirectionService['numberOfBookings'] === 'desc'" class="sort-icon">&#9660;</i>
                          <i *ngIf="sortDirectionService['numberOfBookings'] === ''" class="sort-icon">&#9650;</i>
                        </span>
                      </th>
                      <th (click)="sortServiceTable('totalRevenue')">Total Revenue
                        <span>
                          <i *ngIf="sortDirectionService['totalRevenue'] === 'asc'" class="sort-icon">&#9650;</i>
                          <i *ngIf="sortDirectionService['totalRevenue'] === 'desc'" class="sort-icon">&#9660;</i>
                          <i *ngIf="sortDirectionService['totalRevenue'] === ''" class="sort-icon">&#9650;</i>
                        </span>
                      </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let service of filteredServiceUtilization">
                <td>{{ service.serviceName }}</td>
                <td [ngClass]="{ 'active-status': service.serviceStatus === 'Active', 'inactive-status': service.serviceStatus === 'Inactive' }">
                    {{ service.serviceStatus }}
                </td>
                <td>{{ service.numberOfBookings }}</td>
                <td>{{ service.totalRevenue | currency }}</td>
                </tr>
            </tbody>
            </table>
            </div>
        </div>          
    </p-scrollPanel>
  </div>
  