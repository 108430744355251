<div [ngClass]="state=='open' ? 'main_content_open' : 'main_content'">
    <p-scrollPanel [style]="{width: '100%', height: '95vh','margin-top':'7px','padding-bottom':'70px'}" #sc>

        <div class="report-container">        
            <h2 class="report-title">Client Engagement Report</h2>

            <div class="filter-section">
                <div>
                    <label for="clientSegmentClient">Client Segment:</label>
                    <select id="clientSegmentClient" [(ngModel)]="selectedClientSegmentClient" (change)="onClientSegmentClientChange()">
                        <option value="All">All</option>
                        <option *ngFor="let segment of clientSegments" [value]="segment.id">{{ segment.name }}</option>
                    </select>
                </div>
                
                <div class="date-range-section" style="position: relative; display: flex; align-items: center;">
                    <label for="reportDurationClient">Report Duration:</label>
                    <select id="reportDurationClient" [(ngModel)]="selectedDurationClientEngagement" (change)="onReportDurationChange($event)">
                    <option value="YTD">Year to Date (YTD)</option>
                    <option value="custom">Date Range</option>
                    </select>

                    <div class="date-range" [ngClass]="selectedDurationClientEngagement !== 'custom' ?'disabledbutton':'' ">
                        <label for="startDateClient">Start Date:</label>
                        <p-calendar 
                          class="service-category"
                          id="startDateClient" 
                          [(ngModel)]="startDateClientEngagement" 
                          dateFormat="mm/dd/yy"
                          inputId="startDateClient"
                          [showIcon]="true"
                          placeholder="Enter Start Date" 
                          >
                        </p-calendar>
                    
                        <label for="endDateClient">End Date:</label>
                        <p-calendar 
                          class="service-category"
                          id="endDateClient" 
                          [(ngModel)]="endDateClientEngagement" 
                          dateFormat="mm/dd/yy"
                          inputId="endDateClient"
                          [showIcon]="true"
                          placeholder="Enter End Date"
                          >
                        </p-calendar>
                    
                        <button 
                          (click)="fetchClientEngagementData()" 
                          style="margin-left: 20px; cursor: pointer; padding: 5px 10px; background-color: #2196F3;  color: #fff; border: none;">
                          Apply
                        </button>
                      </div>
                </div>
              </div>
            
            <table class="report-table">
            <thead>
                <tr>                
                <th (click)="sortClientTable('clientName')">Client Name
                    <span>
                      <i *ngIf="sortDirectionClient['clientName'] === 'asc'" class="sort-icon">&#9650;</i>
                      <i *ngIf="sortDirectionClient['clientName'] === 'desc'" class="sort-icon">&#9660;</i>
                      <i *ngIf="sortDirectionClient['clientName'] === ''" class="sort-icon">&#9650;</i>
                    </span>
                  </th>
                  <th (click)="sortClientTable('servicesUsed')">List of Services Used
                    <span>
                      <i *ngIf="sortDirectionClient['servicesUsed'] === 'asc'" class="sort-icon">&#9650;</i>
                      <i *ngIf="sortDirectionClient['servicesUsed'] === 'desc'" class="sort-icon">&#9660;</i>
                      <i *ngIf="sortDirectionClient['servicesUsed'] === ''" class="sort-icon">&#9650;</i>
                    </span>
                  </th>
                  <th (click)="sortClientTable('totalRevenue')">Total Revenue
                    <span>
                      <i *ngIf="sortDirectionClient['totalRevenue'] === 'asc'" class="sort-icon">&#9650;</i>
                      <i *ngIf="sortDirectionClient['totalRevenue'] === 'desc'" class="sort-icon">&#9660;</i>
                      <i *ngIf="sortDirectionClient['totalRevenue'] === ''" class="sort-icon">&#9650;</i>
                    </span>
                  </th>

                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let client of filteredClientEngagement">
                <td>{{ client.clientName }}</td>
                <td>{{ client.servicesUsed.join(', ') }}</td>
                <td>{{ client.totalRevenue | currency }}</td>
                </tr>
            </tbody>
            </table>
        </div>          
    </p-scrollPanel>
  </div>
  