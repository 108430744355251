import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { ClientSegmentationService } from 'src/app/services/client-segmentation/client-segmentation.service';
import { ReportService } from 'src/app/services/report/report.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { AppointmentService } from '../services/appointment/appointment.service';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

interface Service {
  serviceName: string;
  serviceStatus: string;
  numberOfBookings: number;
  totalRevenue: number;
}

interface Clients {
  id: number;
  name: string;
}

@Component({
  selector: 'app-service-utilization-report',
  templateUrl: './service-utilization-report.component.html',
  styleUrls: ['./service-utilization-report.component.css']
})
export class ServiceUtilizationReportsComponent implements OnInit {

  clientSegments: Clients[] = [];

  filteredServiceUtilization: Service[] = [];

  sortDirectionService: { [key: string]: 'asc' | 'desc' | '' } = {
    serviceName: 'asc',
    serviceStatus: '',
    numberOfBookings: '',
    totalRevenue: ''
  };

  selectedDurationServiceUtilization = 'YTD';
  startDateServiceUtilization: string | undefined;
  endDateServiceUtilization: string | undefined;

  startDate: string | undefined;
  endDate: string | undefined;

  selectedClientSegmentService: string = 'All';

  serviceTypeFilterList: any[] = [];

  constructor(
    public utils: UtilsService,
    private reportService: ReportService,
    private clientSegmentService: ClientSegmentationService,
    private appointmentService: AppointmentService,
  ) { }

  ngOnInit(): void {
    this.initializeData();
  }

  get state(): string {
    return this.utils.state;
  }

  initializeData() {
    this.getClientSegments();
    this.loadDataAndFetchServiceUtilization();
  }

  loadDataAndFetchServiceUtilization() {
    if (!this.serviceTypeFilterList || this.serviceTypeFilterList.length === 0) {
      this.getAppointmentFilterOptions().subscribe(
        () => {
          this.fetchServiceUtilizationData();
        },
        (error: any) => {
          console.error('Error fetching Appointment Filter Options:', error);
        }
      );
    } else {
      this.fetchServiceUtilizationData();
    }
  }

  getClientSegments() {
    this.clientSegmentService.getClientSegmentationList().subscribe(
      (response: any) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log(json.data);
        this.clientSegments = json.data;
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  convertDate(dateString: string | undefined): string | undefined {
    if(dateString != undefined){
      const dateObject = new Date(dateString);
      const formattedDate = dateObject.getFullYear() + '-' + 
                        (dateObject.getMonth() + 1).toString().padStart(2, '0') + '-' + 
                        dateObject.getDate().toString().padStart(2, '0');
      return formattedDate;
    }
    return undefined;
  }

  fetchServiceUtilizationData() {
    
    if(this.selectedDurationServiceUtilization === 'custom'){
      this.startDate = this.convertDate(this.startDateServiceUtilization);
      this.endDate = this.convertDate(this.endDateServiceUtilization);
    }

    this.reportService.getServiceUtilizationReport(
        this.selectedClientSegmentService,
        this.selectedDurationServiceUtilization,
        this.startDate,
        this.endDate
      )
      .subscribe(
        (response: any) => {
          var json = JSON.parse(JSON.stringify(response));
          console.log("dateee");
          
          console.log(json.data);
          this.filteredServiceUtilization = json.data;
          const serviceTypeSet = new Set(this.serviceTypeFilterList.map(service => service.value));
          this.filteredServiceUtilization.forEach(service => {
            if (!serviceTypeSet.has(service.serviceName)) {
              service.serviceStatus = 'Inactive';
            }
          });
        },
        (error: any) => {
          console.error(error);
        }
      );
      console.log("final");
      
      console.log(this.serviceTypeFilterList);
      console.log(this.filteredServiceUtilization);
  }


  sortServiceTable(column: keyof Service) {
    const direction = this.sortDirectionService[column] === 'asc' ? 'desc' : 'asc';
    this.sortDirectionService[column] = direction;

    this.filteredServiceUtilization.sort((a, b) => {
      if (a[column] > b[column]) return direction === 'asc' ? 1 : -1;
      if (a[column] < b[column]) return direction === 'asc' ? -1 : 1;
      return 0;
    });
  }

  onClientSegmentServiceChange() {
    this.fetchServiceUtilizationData(); 
  }  

  onReportDurationChange(event: any): void {
    if (this.selectedDurationServiceUtilization === 'YTD') {
      setTimeout(() => {
        this.fetchServiceUtilizationData();
        this.startDateServiceUtilization= undefined;
        this.endDateServiceUtilization= undefined;
      }, 0);
    }
  }

  getAppointmentFilterOptions() {
    return this.appointmentService.getAppointmentFilterOptions().pipe(
      switchMap((response) => {
        const json = JSON.parse(JSON.stringify(response));
        if (json.response.status === 'SUCCESS') {
          this.serviceTypeFilterList = json.data.serviceType.options;
          console.log("Service list fetched successfully:");
          console.log(this.serviceTypeFilterList);
        }
        return of(true);
      })
    );
  }
  
}