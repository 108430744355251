import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { UtilsService } from '../services/utils/utils.service';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { PrivilegeService } from '../services/privilege/privilege.service';
import { element } from 'protractor';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
  animations: [
    trigger('widthGrow', [
      state('closed', style({
        width: 0,
      })),
      state('open', style({
        width: 260
      })),
      transition('* => *', animate(150))
    ]),
  ]
})
export class SidebarComponent implements OnInit {
  items: MenuItem[] = [];
  @Input() from = '';
  constructor(
    private router: Router,
    public utils: UtilsService,
    private privilegeService: PrivilegeService,
    public authenticationService: AuthenticationService
  ) {
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        // console.log('event:', event)
        this.setStyleClass(event.url)
      }
    });
  }
  ngOnInit(): void {
    this.setSidebar()
    this.setStyleClass(this.router.url)
  }

  setSidebar() {
    this.items = [
      {
        label: 'Home',
        icon: 'custom-icon-home',
        // icon: 'pi pi-home',
        routerLink: ['/home'],
        // styleClass: this.checkActiveStateForClass(['/home']),
      },
      {
        label: 'Client Communication',
        icon: 'custom-icon-client-communication',
        visible: this.getPrivilege('customer_notification_view'),
        // icon: 'pi pi-mobile',
        routerLink: ['/client-communication'],
        // styleClass: this.checkActiveStateForClass(['/client-communication'])
      },
      {
        label: 'Client Data Management',
        icon: 'custom-icon-client-data-management',
        visible: this.getPrivilege('customer_add') || this.getPrivilege('customer_management'),
        // icon: 'ti-server',
        routerLink: ['/client-datamanagement'],
        // styleClass: this.checkActiveStateForClass(['/client-datamanagement'])
      },
      // {
      //   label: 'Manage Users',
      //   icon: 'custom-icon-manage-users',
      //   // icon: 'pi pi-users',
      //   routerLink: ['/user'],
      //   visible: this.getPrivilege('businessclient_user_create') || this.getPrivilege('businessclient_user_modify') || this.getPrivilege('businessclient_user_assignrole'),
      //   // styleClass: this.checkActiveStateForClass(['/user'])
      // },
      // {
      //   label: 'Manage Roles',
      //   // icon: 'pi pi-id-card',
      //   icon: 'custom-icon-manage-role',
      //   routerLink: ['/role'],
      //   visible: this.getPrivilege('businessclient_role_create') || this.getPrivilege('businessclient_role_modify'),
      //   // styleClass: this.checkActiveStateForClass(['/role'])
      // },
      // {
      //   label: 'Business Calendar',
      //   // icon: 'pi pi-calendar',
      //   icon: 'custom-icon-business-calendar',
      //   visible: this.getPrivilege('calendar_view'),
      //   routerLink: ['/business-calendar'],
      //   // styleClass: this.checkActiveStateForClass(['/business-calendar'])
      // },
      {
        label: 'Business Calendar',
        // icon: 'pi pi-calendar',
        icon: 'custom-icon-business-calendar',
        visible: this.getPrivilege('calendar_view'),
        // routerLink: ['/business-calendar-modified'],
        routerLink: ['/business-calendar'],
        // styleClass: this.checkActiveStateForClass(['/business-calendar'])
      },
      {
        label: 'Settings',
        // icon: 'pi pi-cog',
        // icon: 'custom-icon-settings',
        icon: 'custom-icon-settings',
        visible: this.getPrivilege('manage_settings'),
        expanded: this.checkActiveState(['/business-information', '/service-type', '/service-provider', '/business-hours', '/message-bot-configuration', '/chat-button-configuration', '/chat-room-configuration', '/business-keywords', '/messages-template', '/configuration-wizard', '/user']),
        // styleClass: this.checkActiveStateForClass(['/business-information', '/service-type', '/service-provider', '/business-hours', '/message-bot-configuration', '/chat-button-configuration', '/chat-room-configuration', '/business-keywords', '/messages-template']),
        items: [
          {
            label: 'Configuration Wizard', 
            // icon: 'pi pi-fw pi-id-card',
            icon: 'custom-icon-configuration-wizard',
            routerLink: ['/configuration-wizard']
          },
          {
            label: 'Business Information',
            // icon: 'pi pi-fw pi-id-card',
            icon: 'custom-icon-business-info',
            routerLink: ['/business-information'],
            // styleClass: this.checkActiveStateForClass(['/business-information'])
          },
          {
            label: 'Manage Services',
            // icon: 'ti pi-fw ti-layers',
            icon: 'custom-icon-manage-service',
            routerLink: ['/service-type'],
            // styleClass: this.checkActiveStateForClass(['/service-type'])
          },
          {
            label: 'Service Provider',
            // icon: 'ti pi-fw ti-user',
            icon: 'custom-icon-service-provider',
            routerLink: ['/service-provider'],
            // styleClass: this.checkActiveStateForClass(['/service-provider'])
          },
          {
            label: 'Manage Users',
            icon: 'custom-icon-manage-users',
            routerLink: ['/user']
          },
          {
            label: 'Manage Roles',
            icon: 'custom-icon-manage-role',
            routerLink: ['/role'],
          },
          {
            label: 'Business Hours & Holidays',
            // icon: 'pi pi-fw pi-calendar',
            icon: 'custom-icon-business-hours',
            routerLink: ['/business-hours'],
            // styleClass: this.checkActiveStateForClass(['/business-hours']),
          },
          // {
          //   label: 'Business Holidays',
          //   // icon: 'pi pi-fw pi-calendar',
          //   icon: 'custom-icon-business-hours',
          //   routerLink: ['/business-holidays'],
          // },
          {
            label: 'Message Bot Configuration',
            // icon: 'ti pi-fw ti-pencil-alt',
            icon: 'custom-icon-message-bot-configuration',
            routerLink: ['/message-bot-configuration'],
            // styleClass: this.checkActiveStateForClass(['/message-bot-configuration']),
          },
          {
            label: 'Chat Button Configuration',
            // icon: 'ti pi-fw ti-comment-alt',
            icon: 'custom-icon-chat-button-configuration',
            routerLink: ['/chat-button-configuration'],
            // styleClass: this.checkActiveStateForClass(['/chat-button-configuration']),
          },
          {
            label: 'Chat Room Configuration',
            // icon: 'ti pi-fw ti-palette',
            icon: 'custom-icon-chat-room-configuration',
            routerLink: ['/chat-room-configuration'],
            // styleClass: this.checkActiveStateForClass(['/chat-room-configuration']),
          },
          {
            label: 'Chat Button Promotion',
            // icon: 'ti pi-fw ti-volume',
            icon: 'custom-icon-chat-button-promotion',
            routerLink: ['/chat-button-promotion'],
          },
          {
            label: 'Business Keywords',
            // icon: 'ti pi-fw  ti-clipboard',
            icon: 'custom-icon-business-keywords',
            routerLink: ['/business-keywords'],
            // styleClass: this.checkActiveStateForClass(['/business-keywords']),
          },
          {
            label: 'Messages Template',
            // icon: 'ti pi-fw ti-clipboard',
            icon: 'custom-icon-messages-template',
            routerLink: ['/messages-template'],
            // styleClass: this.checkActiveStateForClass(['/messages-template']),
          },
          {
            label: 'Action Button Configuration',
            // icon: 'ti pi-fw ti-pencil-alt',
            icon: 'custom-icon-action-button-configuration',
            routerLink: ['/action-button'],
          },
          {
            label: 'Client Segmentation',
            // icon: 'fa fa-th-large',
            icon: 'custom-icon-client-segmentation',
            routerLink: ['/client-segmentation'],
          },
          {
            label: 'Reports',
            // icon: 'fa fa-th-large',
            icon: 'custom-icon-client-segmentation',
            // routerLink: ['/reports'],
            expanded: this.checkActiveState(['/service-utilization-report', '/client-engagement-report']),
            items: [
              {
                label: 'Service Utilization Report',
                // icon: 'ti pi-fw ti-clipboard',
                icon: 'custom-icon-client-segmentation',
                routerLink: ['/service-utilization-report'],
                // visible: this.getPrivilege('businessportal_faq_view'),
                // styleClass: this.checkActiveStateForClass(['/frequently-asked-questions'])
              },
              {
                label: 'Client Engagement Report',
                // icon: 'ti pi-fw ti-clipboard',
                icon: 'custom-icon-client-segmentation',
                routerLink: ['/client-engagement-report'],
                // styleClass: this.checkActiveStateForClass(['/how-to'])
              }],
          }
        ]
      },
      {
        label: 'Support',
        // icon: 'fa fa-user-o',
        icon: 'custom-icon-support',
        expanded: this.checkActiveState(['/frequently-asked-questions', '/how-to', '/support-tickets', '/support-chat']),
        // styleClass: this.checkActiveStateForClass(['/frequently-asked-questions', '/how-to', '/support-tickets', '/support-chat']),
        items: [
          {
            label: 'Frequently Asked Questions',
            // icon: 'ti pi-fw ti-clipboard',
            icon: 'custom-icon-faq',
            routerLink: ['/frequently-asked-questions'],
            // visible: this.getPrivilege('businessportal_faq_view'),
            // styleClass: this.checkActiveStateForClass(['/frequently-asked-questions'])
          },
          {
            label: 'How To Guide',
            // icon: 'ti pi-fw ti-clipboard',
            icon: 'custom-icon-how-to-Guide',
            routerLink: ['/how-to'],
            // styleClass: this.checkActiveStateForClass(['/how-to'])
          },
          {
            label: 'Support Tickets',
            // icon: 'ti pi-fw ti-ticket',
            icon: 'custom-icon-support-tickets',
            routerLink: ['/support-tickets'],
            // visible: this.getPrivilege('businessclient_support'),
            // styleClass: this.checkActiveStateForClass(['/support-tickets'])
          },
          {
            label: 'Chat with Support',
            // icon: 'ti pi-fw ti-comment',
            icon: 'custom-icon-chat-with-support',
            routerLink: ['/support-chat'],
            // visible: this.getPrivilege('businessportal_fsupport_chat'),
            // styleClass: this.checkActiveStateForClass(['/support-chat'])
          },
        ]
      },
    ];
  }
  checkActiveState(givenLink: any[]): boolean {
    let value = false
    // console.log(this.router.url);
    for (let name of givenLink) {
      if (this.router.url.indexOf(name) === -1) {
        value = false;
      } else {
        value = true;
        break;
      }
    }
    return value;
  }

  checkActiveStateForClass(givenLink: any[]): string {
    // console.log(this.router.url);
    let value = ''
    for (let name of givenLink) {
      if (this.router.url.indexOf(name) === -1) {
        value = '';
      } else {
        value = 'menucus';
        break;
      }
    }
    return value;
  }

  setStyleClass(link: string) {
    let index = this.items.findIndex((obj: MenuItem) => {
      if (!obj.hasOwnProperty('items')) {
        return obj.routerLink[0] == link
      } else {
        return obj?.items?.findIndex(obj => obj.routerLink[0] == link)
      }
    })

    outer: for (let parentIndex = 0; parentIndex < this.items.length; parentIndex++) {
      let element = this.items[parentIndex];
      if (element.hasOwnProperty('items') && element != undefined) {
        for (let childIndex = 0; childIndex < element.items!.length; childIndex++) {
          let data: any = element!.items![childIndex]
          if (data.routerLink[0] == link) {
            data.styleClass = 'menucus'
          } else {
            data.styleClass = ''
          }
        }
        for (let childIndex = 0; childIndex < element.items!.length; childIndex++) {
          let data: any = element!.items![childIndex]
          if (data.routerLink[0] == link) {
            element.styleClass = 'menucus'
            continue outer;
          } else {
            element.styleClass = ''
          }
        }
        // console.log('parent:', element)
      } else {
        if (element.routerLink[0] == link) {
          this.items[parentIndex].styleClass = 'menucus'
        } else {
          this.items[parentIndex].styleClass = ''
        }
      }
    }
  }

  activeMenu(event: any) {
    // console.log(this.router.url);
  }

  getPrivilege(id: any) {
    return this.privilegeService.getPrivileges(id);
  }

  get state(): string {
    return this.utils.state;
  }

}
