import { Component, OnInit } from '@angular/core';
import { ClientSegmentationService } from 'src/app/services/client-segmentation/client-segmentation.service';
import { ReportService } from 'src/app/services/report/report.service';
import { UtilsService } from 'src/app/services/utils/utils.service';

interface Client {
  clientName: string;
  servicesUsed: string[];
  totalRevenue: number;
}

interface Clients {
  id: number;
  name: string;
}

@Component({
  selector: 'app-report-client-engagement-report',
  templateUrl: './client-engagement-report.component.html',
  styleUrls: ['./client-engagement-report.component.css']
})
export class ClientEngagementReportComponent implements OnInit {

  clientSegments: Clients[] = [];

  filteredClientEngagement: Client[] = [];

  sortDirectionClient: { [key: string]: 'asc' | 'desc' | '' } = {
    clientName: 'asc',
    servicesUsed: '',
    totalRevenue: '' 
  };

  selectedDurationClientEngagement = 'YTD';
  startDateClientEngagement: string | undefined;
  endDateClientEngagement: string | undefined;

  
  startDate: string | undefined;
  endDate: string | undefined;

  selectedClientSegmentClient: string = 'All';

  constructor(
    public utils: UtilsService,
    private reportService: ReportService,
    private clientSegmentService: ClientSegmentationService
  ) { }

  ngOnInit(): void {
    this.getClientSegments();
    this.fetchClientEngagementData(); 
  }

  get state(): string {
    return this.utils.state;
  }

  getClientSegments() {
    this.clientSegmentService.getClientSegmentationList().subscribe(
      (response: any) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log(json.data);
        this.clientSegments = json.data;
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  convertDate(dateString: string | undefined): string | undefined {
    if(dateString != undefined){
      const dateObject = new Date(dateString);
      const formattedDate = dateObject.getFullYear() + '-' + 
                        (dateObject.getMonth() + 1).toString().padStart(2, '0') + '-' + 
                        dateObject.getDate().toString().padStart(2, '0');
      return formattedDate;
    }
    return undefined;
  }

  fetchClientEngagementData() {
    if(this.selectedDurationClientEngagement === 'custom'){
      this.startDate = this.convertDate(this.startDateClientEngagement);
      this.endDate = this.convertDate(this.endDateClientEngagement);
    }
    
    this.reportService
      .getClientEngagementReport(
        this.selectedClientSegmentClient,
        this.selectedDurationClientEngagement,
        this.startDate,
        this.endDate
      )
      .subscribe(
        (response: any) => {
          var json = JSON.parse(JSON.stringify(response));
          console.log(json.data);
          this.filteredClientEngagement = json.data; 
        },
        (error: any) => {
          console.error(error);
        }
      );
  }

  sortClientTable(column: keyof Client) {
    const direction = this.sortDirectionClient[column] === 'asc' ? 'desc' : 'asc';
    this.sortDirectionClient[column] = direction;

    this.filteredClientEngagement.sort((a, b) => {
      if (a[column] > b[column]) return direction === 'asc' ? 1 : -1;
      if (a[column] < b[column]) return direction === 'asc' ? -1 : 1;
      return 0;
    });
  }

  onClientSegmentClientChange() {
    this.fetchClientEngagementData();
  }

  onReportDurationChange(event: any): void {
    if (this.selectedDurationClientEngagement === 'YTD') {
      setTimeout(() => {
        this.fetchClientEngagementData();
        this.startDateClientEngagement= undefined;
        this.endDateClientEngagement= undefined;
      }, 0);
    }
  }
}