import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { timeout } from 'rxjs/operators';
import { CommonFunctions } from 'src/app/Utilities/CommonFunctions';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ServiceTypeService {

  constructor(
    private http: HttpClient
  ) { }

  serviceTypeList() {
    const url = environment.API_URL + "/api/serviceType";
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + TOKEN,
        "Content-Type": "application/json",
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  serviceTypeListWithPaginate(pageNumber: any, searchText: string, sortData: any) {
    let params = new HttpParams().set('page', pageNumber)
    if (searchText != null && searchText != '') {
      params = params.set('search', searchText);
    }
    if (sortData != null && sortData != '') {
      params = params.set('sortBy', sortData.sortBy);
    }
    if (sortData != null && sortData != '') {
      params = params.set('sortDirection', sortData.sortDirection);
    }
    const url = environment.API_URL + "/api/serviceType";
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + TOKEN,
        "Content-Type": "application/json",
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
      params: params
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }


  addServiceType(serviceType: any) {
    const url = environment.API_URL + "/api/serviceType/add";
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + TOKEN,
        "Content-Type": "application/json",
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.post(url, serviceType, httpOptions).pipe(timeout(60000));
  }

  editServiceType(service_id: any, serviceType: any) {
    const url = environment.API_URL + "/api/serviceType/" + service_id + '/update';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + TOKEN,
        "Content-Type": "application/json",
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.post(url, serviceType, httpOptions).pipe(timeout(60000));
  }


  getSingleServiceType(service_id: any) {
    const url = environment.API_URL + "/api/serviceType/" + service_id;
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + TOKEN,
        "Content-Type": "application/json",
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  removeServiceType(service_id: any) {
    const url = environment.API_URL + "/api/serviceType/" + service_id + "/remove";
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + TOKEN,
        "Content-Type": "application/json",
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.post(url, null, httpOptions).pipe(timeout(60000));
  }

  serviceFileUpload(file: any, fileType:any, serviceId:any, index:any) {
    // console.log('Profile Swrevice Data ---> ' + JSON.stringify(file));
    const url = environment.API_URL + '/api/serviceType/fileupload';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    // Create form data
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('fileType',fileType);
    formData.append('serviceId',serviceId);
    formData.append('index', index)
    return this.http.post(url, formData, httpOptions).pipe(timeout(60000));
  }

  removeServiceTypeFile(id: any) {
    const url = environment.API_URL + "/api/serviceType/" + id + "/fileremove";
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + TOKEN,
        "Content-Type": "application/json",
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.post(url, null, httpOptions).pipe(timeout(60000));
  }

  getSlotByDateAndServiceId(serviceId: any, date: any) {
    const url = environment.API_URL + "/api/appointment/getSlotByDateAndServiceId?serviceId=" + serviceId + '&startDate=' + date;
    const
      TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + TOKEN,
        "Content-Type": "application/json",
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

}
